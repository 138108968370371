import React, { ReactNode } from 'react';

import {
  LightboxItem,
  OnLightboxImageDownload
} from '../../LightboxWrapper.types';

import { LightboxWrapperOpenInNewTabButton } from '../LightboxWrapperOpenInNewTabButton';
import { LightboxWrapperImageEditButton } from '../LightboxWrapperImageEditButton';
import { LightboxWrapperDownloadButton } from '../LightboxWrapperDownloadButton';
import { TooltipSingletonSourceWrapper } from '../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { TooltipPlacement } from '../../../tooltips/tooltipsConstants';

export interface LightboxWrapperBottomContentProps {
  customButtons?: ReactNode;
  imageItem: LightboxItem;
  onImageDownload?: OnLightboxImageDownload;
  onImageEdit: () => void;
  withDownloadButton?: boolean;
  withFullScreenButton?: boolean;
  withImageEditButton?: boolean;
}

function LightboxWrapperBottomContent({
  customButtons,
  imageItem,
  onImageDownload,
  onImageEdit,
  withDownloadButton,
  withFullScreenButton,
  withImageEditButton
}: LightboxWrapperBottomContentProps) {
  return (
    <div className="flex">
      <div className="flex-1 p-3 text-left"></div>
      <div className="flex-1 p-3 text-center">
        <div className="flex items-center justify-center space-x-0.5 mx-auto">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.TOP}
            withArrow
          >
            {customButtons}

            {withImageEditButton && (
              <LightboxWrapperImageEditButton onImageEdit={onImageEdit} />
            )}

            {withFullScreenButton && (
              <LightboxWrapperOpenInNewTabButton file={imageItem.file} />
            )}

            {withDownloadButton && (
              <LightboxWrapperDownloadButton
                uuid={imageItem.uuid}
                onDownload={onImageDownload}
              />
            )}
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
      <div className="flex-1 p-3 text-right"></div>
    </div>
  );
}

export default LightboxWrapperBottomContent;
