import React, { Fragment, useCallback, useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import isFunction from 'lodash/isFunction';

import 'react-image-lightbox/style.css';

import { FileUrl } from '../../types';

import {
  LightboxItem,
  LightboxRenderCustomButtons,
  LightboxRenderImageTitle,
  OnLightboxImageDownload,
  OnLightboxImageEditSubmit
} from './LightboxWrapper.types';

import { ImageEditor, useImageEditor } from '../ImageEditor';
import { useCurrentUser } from '../../auth/hooks/useAuth';

import { LightboxWrapperBottomContent } from './helpers/LightboxWrapperBottomContent';
import { LightboxWrapperBaseImageTitle } from './helpers/LightboxWrapperBaseImageTitle';

import { LightboxWrapperPermissions } from './lightboxWrapperConstants';

interface LightboxWrapperProps {
  handleLightboxClose: () => void;
  handleLightboxNext: () => void;
  handleLightboxOpen: (index: number) => void;
  handleLightboxPrev: () => void;
  imageItem: LightboxItem;
  imagesCount: number;
  index: number;
  lightBoxOpened: boolean;
  mainSrc?: FileUrl;
  mainSrcThumbnail?: FileUrl;
  nextSrc?: FileUrl;
  nextSrcThumbnail?: FileUrl;
  onImageDownload?: OnLightboxImageDownload;
  onImageEditSubmit?: OnLightboxImageEditSubmit;
  prevSrc?: FileUrl;
  prevSrcThumbnail?: FileUrl;
  renderCustomButtons?: LightboxRenderCustomButtons;
  renderImageTitle?: LightboxRenderImageTitle;
  toggleImageEditorBackdrop?: () => void;
  withBaseImageTitle?: boolean;
  withFullScreenButton?: boolean;
}

function LightboxWrapper({
  handleLightboxClose,
  handleLightboxNext,
  handleLightboxOpen,
  handleLightboxPrev,
  imageItem,
  imagesCount,
  index,
  lightBoxOpened,
  mainSrc,
  mainSrcThumbnail,
  nextSrc,
  nextSrcThumbnail,
  onImageDownload,
  onImageEditSubmit,
  prevSrc,
  prevSrcThumbnail,
  renderCustomButtons,
  renderImageTitle,
  toggleImageEditorBackdrop,
  withBaseImageTitle,
  withFullScreenButton
}: LightboxWrapperProps) {
  const currentUser = useCurrentUser();

  const [image, setImage] = useState<string | null | undefined>(null);

  const { enabled, hideEditor, toggleEnabled } = useImageEditor({
    toggleBackdrop: toggleImageEditorBackdrop
  });

  const handleImageEdit = useCallback<() => void>(() => {
    handleLightboxClose();
    setImage(mainSrc);
    toggleEnabled();
  }, [mainSrc, handleLightboxClose, setImage, toggleEnabled]);

  const handleCancelEdit = useCallback<() => void>(
    () => handleLightboxOpen(index),
    [index, handleLightboxOpen]
  );

  const bottomContent = useMemo(() => {
    return (
      <LightboxWrapperBottomContent
        customButtons={renderCustomButtons && renderCustomButtons({ index })}
        imageItem={imageItem}
        withFullScreenButton={withFullScreenButton}
        withImageEditButton={isFunction(onImageEditSubmit)}
        withDownloadButton={
          currentUser.hasPermissions(
            LightboxWrapperPermissions.READ_LIGHTBOX_DOWNLOAD_BUTTON
          ) && isFunction(onImageDownload)
        }
        onImageDownload={onImageDownload}
        onImageEdit={handleImageEdit}
      />
    );
  }, [
    currentUser,
    handleImageEdit,
    imageItem,
    index,
    onImageDownload,
    onImageEditSubmit,
    renderCustomButtons,
    withFullScreenButton
  ]);

  const imageTitle = useMemo(() => {
    if (withBaseImageTitle) {
      return (
        <LightboxWrapperBaseImageTitle
          imageItem={imageItem}
          index={index}
          imagesCount={imagesCount}
        />
      );
    }

    if (!renderImageTitle) {
      return null;
    }

    return renderImageTitle({ index });
  }, [withBaseImageTitle, renderImageTitle, index, imageItem, imagesCount]);

  return (
    <Fragment>
      {lightBoxOpened && mainSrc ? (
        <Lightbox
          mainSrc={mainSrc}
          nextSrc={nextSrc}
          prevSrc={prevSrc}
          mainSrcThumbnail={mainSrcThumbnail}
          nextSrcThumbnail={nextSrcThumbnail}
          prevSrcThumbnail={prevSrcThumbnail}
          onCloseRequest={handleLightboxClose}
          onMovePrevRequest={handleLightboxPrev}
          onMoveNextRequest={handleLightboxNext}
          imageTitle={imageTitle}
          imageCaption={bottomContent}
        />
      ) : null}
      {image && onImageEditSubmit ? (
        <ImageEditor
          enabled={enabled}
          image={image}
          hideEditor={hideEditor}
          uploadFileType="fileAttachment"
          onCancel={handleCancelEdit}
          onSubmit={onImageEditSubmit}
        />
      ) : null}
    </Fragment>
  );
}

export default LightboxWrapper;
