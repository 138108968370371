import { useCallback, useReducer, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { ClientError } from 'graphql-request';
import flatten from 'lodash/flatten';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';

import { LocalForage } from '../../../../../../utils/LocalForage';

import {
  FetchItemsGqlQuery,
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsLimit,
  UUID,
  FetchItemCacheKey,
  FetchItemGqlQuery
} from '../../../../../../types';

import {
  InfiniteIndexQueryResponse,
  InfiniteIndexQueryData,
  InfiniteIndexQueryBaseNodeType,
  InfiniteIndexQueryDefaultOptionsOpts
} from './useInfiniteIndexQuery.types';

import { useUpdateInfiniteIndexQueryItemCache } from './hooks/useUpdateInfiniteIndexQueryItemCache';
import { useAddInfiniteIndexQueryItemCache } from './hooks/useAddInfiniteIndexQueryItemCache';

import { parseRequestError } from '../../../../../../utils/parseRequestError';

import {
  indexRequestReducer,
  IndexRequestReducerType
} from './reducers/indexRequestReducer';

import { fetchItems } from '../baseActions/fetchItems';

import { changeItemsFiltersAction } from './actions/changeItemsFiltersAction';
import { clearItemsFiltersAction } from './actions/clearItemsFiltersAction';
import { filterItemsAction } from './actions/filterItemsAction';
import { sortItemsAction } from './actions/sortItemsAction';
import { limitItemsAction } from './actions/limitItemsAction';

import {
  INITIAL_FILTERS,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT
} from './infiniteIndexRequestConstants';

import { retryCustomFunction } from './utils/retryCustomFunction';
import { fetchItem } from '../baseActions/fetchItem';

type InfiniteIndexErrorType = Error | ClientError;

type InfiniteIndexQueryWithFetchItemOptions = {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemGqlQuery;
};

type InfiniteIndexQueryWithoutFetchItemOptions = {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
};

interface InfiniteIndexQueryOptions<NodeType> {
  cacheKey: string;
  query: FetchItemsGqlQuery;
  scope: string;
  initialFilters?: FetchItemsFilters;
  initialSort?: FetchItemsSort;
  initialLimit?: FetchItemsLimit;
  options?: InfiniteIndexQueryDefaultOptionsOpts<NodeType>;
}

function useInfiniteIndexQuery<
  NodeType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  scope,
  query,
  initialFilters = INITIAL_FILTERS,
  initialSort = INITIAL_SORT,
  initialLimit = INITIAL_LIMIT,
  options = {},
  fetchItemCacheKey,
  fetchItemQuery
}: InfiniteIndexQueryOptions<NodeType> &
  (
    | InfiniteIndexQueryWithFetchItemOptions
    | InfiniteIndexQueryWithoutFetchItemOptions
  )) {
  const localForageCacheKey = `${cacheKey}-infinite-index`;

  const [{ currentFilters, currentLimit, currentSort }, dispatch] =
    useReducer<IndexRequestReducerType>(indexRequestReducer, {
      currentLimit: initialLimit,
      currentFilters: initialFilters,
      currentSort: initialSort
    });

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useInfiniteQuery<InfiniteIndexQueryResponse<NodeType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<InfiniteIndexQueryResponse<NodeType>>(
          localForageCacheKey
        ),
      {
        enabled: options.enabledPlaceholder
      }
    );

  const currentParams = useMemo(() => {
    return {
      filters: currentFilters,
      sort: currentSort,
      limit: currentLimit
    };
  }, [currentFilters, currentSort, currentLimit]);

  const fullCacheKey = useMemo(() => {
    return [cacheKey, currentParams];
  }, [cacheKey, currentParams]);

  const {
    data,
    isFetched,
    isLoading,
    error,
    isPlaceholderData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch
  } = useInfiniteQuery<
    InfiniteIndexQueryResponse<NodeType>,
    InfiniteIndexErrorType
  >(
    fullCacheKey,
    useCallback(
      ({ pageParam = INITIAL_PAGE }) =>
        fetchItems({
          query,
          page: pageParam,
          ...currentParams
        }),
      [currentParams, query]
    ),
    {
      enabled:
        options.enabled ||
        (options.enabledPlaceholder && placeholderDataFetched),
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      retry: retryCustomFunction,
      onSuccess: useCallback(
        (data) => {
          options.onSuccess?.(data);
          if (
            data?.pages[0] &&
            size(data?.pages) === 1 &&
            isEqual(currentFilters, initialFilters) &&
            isEqual(currentSort, initialSort) &&
            currentLimit === initialLimit
          ) {
            return LocalForage.setItem<InfiniteIndexQueryResponse<NodeType>>(
              localForageCacheKey,
              data.pages[0]
            );
          }
        },
        [
          currentFilters,
          currentSort,
          currentLimit,
          initialFilters,
          initialSort,
          initialLimit,
          localForageCacheKey,
          options
        ]
      ),
      placeholderData: useCallback(() => {
        if (
          placeholderData?.pages?.[0] &&
          isEqual(currentFilters, initialFilters) &&
          isEqual(currentSort, initialSort) &&
          currentLimit === initialLimit
        ) {
          return placeholderData as InfiniteIndexQueryData<NodeType>;
        }
      }, [
        currentFilters,
        currentSort,
        currentLimit,
        initialFilters,
        initialSort,
        initialLimit,
        placeholderData
      ]),
      getNextPageParam: useCallback(
        (lastPage) => {
          return lastPage?.[scope]?.paginationInfo?.nextPage ?? undefined;
        },
        [scope]
      )
    }
  );

  const loadMoreItems = useCallback(() => fetchNextPage(), [fetchNextPage]);

  const lastQueryResponseValue = last(data?.pages)?.[scope];
  const placeholderResponseValue = placeholderData?.pages?.[0]?.[scope];

  const addItemCache = useAddInfiniteIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const updateItemCache = useUpdateInfiniteIndexQueryItemCache<NodeType>({
    fullCacheKey,
    scope
  });

  const items = useMemo<NodeType[]>(() => {
    const pagesNodes = data?.pages?.map((page) => page?.[scope]?.nodes);
    return pagesNodes ? flatten(pagesNodes) : [];
  }, [data, scope]);

  const isLoadingTotalCount = isLoading
    ? placeholderResponseValue?.paginationInfo?.totalCount
    : null;

  const queryClient = useQueryClient();

  return {
    data,
    items,
    itemsError: parseRequestError(error),
    itemsTotalCount:
      lastQueryResponseValue?.paginationInfo?.totalCount ||
      isLoadingTotalCount ||
      0,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentFilters,
    currentSort,
    currentPage: lastQueryResponseValue?.paginationInfo?.currentPage,
    currentLimit,
    hasNextPage,
    addItemCache,
    updateItemCache,
    loadMoreItems,
    refetch,
    filterItems: useCallback(
      (nextFilters: FetchItemsFilters) =>
        dispatch(filterItemsAction(nextFilters)),
      [dispatch]
    ),
    changeItemsFilters: useCallback(
      (
        changedFilters: Partial<FetchItemsFilters>,
        removeFilters: string[] = []
      ) => dispatch(changeItemsFiltersAction(changedFilters, removeFilters)),
      [dispatch]
    ),
    clearItemsFilters: useCallback(
      () => dispatch(clearItemsFiltersAction()),
      [dispatch]
    ),
    clearItemsFiltersPersistInitial: useCallback(
      () => dispatch(filterItemsAction(initialFilters)),
      [dispatch, initialFilters]
    ),
    sortItems: useCallback(
      (nextSort: FetchItemsSort) => dispatch(sortItemsAction(nextSort)),
      [dispatch]
    ),
    limitItems: useCallback(
      (nextLimit: FetchItemsLimit) => dispatch(limitItemsAction(nextLimit)),
      [dispatch]
    ),
    prefetchItem: useCallback(
      (itemUuid: UUID) =>
        queryClient.prefetchQuery(
          [fetchItemCacheKey, itemUuid],
          () =>
            fetchItem({
              query: fetchItemQuery,
              uuid: itemUuid
            }),
          { staleTime: 5000 }
        ),
      [queryClient, fetchItemCacheKey, fetchItemQuery]
    )
  };
}

export default useInfiniteIndexQuery;
