import React, { useCallback, useMemo } from 'react';
import size from 'lodash/size';
import sortBy from 'lodash/sortBy';

import { MessagesListMessageImagesMessage } from './MessagesListMessageImages.types';
import {
  MessageAttachmentTypes,
  MessageFinAttachmentTypes
} from '../../../../../messagesTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { FileAttachmentItemImageVersions } from '../../../../../../../helpers/ImageHelper';

import {
  mapMessageAttachmentImage,
  MessagesListMessageImage
} from '../MessagesListMessageImage';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useDownloadTypeFile } from '../../../../../../s3Multipart/hooks/useDownloadTypeFile';
import { useImageEditorSubmitWithItemNanoId } from '../../../../../../../helpers/ImageEditor/hooks/useImageEditorSubmitWithItemNanoId';

import {
  LightboxRenderImageTitle,
  LightboxWrapper,
  useLightboxWrapper,
  LightboxThumbnails,
  OnLightboxImageDownload
} from '../../../../../../../helpers/LightboxWrapper';
import { ModelLightboxTitle } from '../../../../../../common/helpers/ModelLightboxTitle';
import { Files } from '../../../../../../../utils/Files';

import { LightboxWrapperPermissions } from '../../../../../../../helpers/LightboxWrapper/lightboxWrapperConstants';
import { MessagesPermissions } from '../../../../../messagesConstants';
import { ModelLightboxTitleAttachment } from '../../../../../../common/helpers/ModelLightboxTitle/ModelLightboxTitle';

interface MessagesListMessageImagesProps {
  message: MessagesListMessageImagesMessage;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function MessagesListMessageImages({
  message,
  togglePreventModalClose
}: MessagesListMessageImagesProps) {
  const currentUser = useCurrentUser();

  const fileAttachments =
    message.fileAttachments?.filter((fileAttachment) =>
      Files.isImage(fileAttachment.file)
    ) || [];
  const selectedProducts = message.selectedProducts || [];
  const selectedLifestyles = message.selectedLifestyles || [];
  const selectedMaterials = message.selectedMaterials || [];
  const attachments = sortBy(
    [
      ...fileAttachments,
      ...selectedProducts,
      ...selectedLifestyles,
      ...selectedMaterials
    ],
    (attachment) => attachment.createdAt
  );

  const attachmentsInLightbox = useMemo(() => {
    return attachments.filter((attachment) => {
      const lightboxImage = mapMessageAttachmentImage(attachment);
      return lightboxImage && Files.isImage(lightboxImage?.file);
    });
  }, [attachments]);

  const lightboxItems = useMemo(() => {
    return attachmentsInLightbox.map(mapMessageAttachmentImage);
  }, [attachmentsInLightbox]);

  const lightboxThumbnails = useMemo<LightboxThumbnails>(() => {
    return attachmentsInLightbox.map((attachment) =>
      attachment.type === MessageAttachmentTypes.FILE_ATTACHMENT
        ? Files.urlFromFile(
            attachment.file,
            FileAttachmentItemImageVersions.FullScreenThumb1000x850
          )
        : null
    );
  }, [attachmentsInLightbox]);

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc,
    mainSrcThumbnail,
    prevSrcThumbnail,
    nextSrcThumbnail
  } = useLightboxWrapper({
    items: lightboxItems,
    thumbnails: currentUser.hasPermissions(
      LightboxWrapperPermissions.READ_LIGHTBOX_PLACEHOLDER_THUMBNAILS
    )
      ? lightboxThumbnails
      : null,
    toggleBackdrop: togglePreventModalClose
  });

  const { onDownload } = useDownloadTypeFile({});

  const handleDownload = useCallback<OnLightboxImageDownload>(
    (uuid) => {
      const item = attachments?.find((item) => {
        if (
          item.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
          item.type === MessageFinAttachmentTypes.FILE_ATTACHMENT
        ) {
          return item.uuid === uuid;
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
          item.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE
        ) {
          return item.lifestyle.image.uuid === uuid;
        }

        if (
          item.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
          item.type === MessageFinAttachmentTypes.SELECTED_PRODUCT
        ) {
          return item.product.image.uuid === uuid;
        }

        if (
          item.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
          item.type === MessageFinAttachmentTypes.SELECTED_MATERIAL
        ) {
          return item.material.image.uuid === uuid;
        }
      });

      if (item) {
        if (
          item.type === MessageAttachmentTypes.FILE_ATTACHMENT ||
          item.type === MessageFinAttachmentTypes.FILE_ATTACHMENT
        ) {
          onDownload(item.uuid, 'fileAttachments');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_LIFESTYLE ||
          item.type === MessageFinAttachmentTypes.SELECTED_LIFESTYLE
        ) {
          onDownload(item.lifestyle.uuid, 'lifestyles');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_PRODUCT ||
          item.type === MessageFinAttachmentTypes.SELECTED_PRODUCT
        ) {
          onDownload(item.product.uuid, 'products');
        }
        if (
          item.type === MessageAttachmentTypes.SELECTED_MATERIAL ||
          item.type === MessageFinAttachmentTypes.SELECTED_MATERIAL
        ) {
          onDownload(item.material.uuid, 'materials');
        }
      }
    },
    [attachments, onDownload]
  );

  const { handleImageEditorSubmitWithItemNanoId } =
    useImageEditorSubmitWithItemNanoId(
      message.project?.nanoId
        ? { projectNanoId: message.project.nanoId }
        : { taskNanoId: message.task?.nanoId }
    );

  const currentLightboxImageAttachment = attachmentsInLightbox[index];

  const attachmentsTotalCount = size(attachments);

  const renderImageTitle = useCallback<LightboxRenderImageTitle>(() => {
    if (!currentLightboxImageAttachment) {
      return null;
    }

    if (
      currentLightboxImageAttachment.type !==
        MessageAttachmentTypes.FILE_ATTACHMENT &&
      currentLightboxImageAttachment.type !==
        MessageFinAttachmentTypes.FILE_ATTACHMENT
    ) {
      return (
        <ModelLightboxTitle
          attachment={
            currentLightboxImageAttachment as ModelLightboxTitleAttachment
          }
        />
      );
    }
  }, [currentLightboxImageAttachment]);

  if (attachmentsTotalCount === 0) {
    return null;
  }

  return (
    <>
      {attachments.map((attachment) => (
        <MessagesListMessageImage
          key={attachment.uuid}
          attachment={attachment}
          message={message}
          onLightboxOpen={handleLightboxOpenOnSlide}
        />
      ))}

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        mainSrcThumbnail={mainSrcThumbnail}
        nextSrcThumbnail={nextSrcThumbnail}
        prevSrcThumbnail={prevSrcThumbnail}
        withFullScreenButton
        renderImageTitle={
          currentLightboxImageAttachment?.type !==
            MessageAttachmentTypes.FILE_ATTACHMENT &&
          currentLightboxImageAttachment?.type !==
            MessageFinAttachmentTypes.FILE_ATTACHMENT
            ? renderImageTitle
            : undefined
        }
        withBaseImageTitle={
          currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
          currentLightboxImageAttachment?.type ===
            MessageFinAttachmentTypes.FILE_ATTACHMENT
        }
        toggleImageEditorBackdrop={togglePreventModalClose}
        onImageEditSubmit={
          (currentLightboxImageAttachment?.type ===
            MessageAttachmentTypes.FILE_ATTACHMENT ||
            currentLightboxImageAttachment?.type ===
              MessageFinAttachmentTypes.FILE_ATTACHMENT) &&
          currentUser.hasPermissions(
            MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
          )
            ? handleImageEditorSubmitWithItemNanoId
            : undefined
        }
        onImageDownload={handleDownload}
      />
    </>
  );
}

export default MessagesListMessageImages;
